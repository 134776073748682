import React, { useContext } from "react";
import styled from "styled-components";
import { Redirect, Route, Switch } from "react-router-dom";
import RouteConstants from "./constants/RouteConstants";
import Home from "./containers/Home";
import ResetPassword from "./containers/ResetPassword";
import UnlockLockAccount from "./containers/UnlockLockAccount";

const Container = styled.div.attrs({
  className: "container-fluid"
})`
  @media (max-width: 767px) {
    margin-top: ${props => (props.isProd ? "65px" : props.loggedIn ? "85px" : "30px")};
  }
  @media (min-width: 768px) {
    margin-top: ${props => (props.loggedIn ? "90px" : "30px")};
  }
  margin-bottom: 1.25rem;
`;

const AppContainer = () => {
  return (
    <Container isProd={false} loggedIn={true}>
      <Switch>
        <Route path={RouteConstants.BASE} exact component={Home} />
        <Route path={RouteConstants.RESET_PASSWORD} component={ResetPassword} />
        <Route path={RouteConstants.UNLOCK_LOCK_ACCOUNT} component={UnlockLockAccount} />
        <Redirect from={RouteConstants.WILDCARD} to={RouteConstants.BASE} />
      </Switch>
    </Container>
  );
};

export default AppContainer;
