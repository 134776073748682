export default {
  WILDCARD: "*",
  BASE: "/",
  LOGIN: "/login",
  RESET_PASSWORD: "/reset-password",
  UNLOCK_LOCK_ACCOUNT: "/unlock-lock-account"
};

export const urlReplace = (str, data) => {
  const keys = Object.keys(data);
  keys.forEach(key => {
    str = str.replace(`:${key}`, data[key]);
  });
  return str;
};
