import React, { useState, useContext } from "react";
import styled from "styled-components";
import { CardContainer, CardHeader, CardBody, Input, PrimaryButton, Table, TableHeader, Icon } from "best-common-react";
import { searchUsersByEmail } from "../api/UserMgmtApi";
import UserListContext from "../contexts/UserListContext";
import ResetPasswordModal from "../components/users/ResetPasswordModal";

export const InputStyled = styled(Input)`
  &&& {
    width: 220px;
  }
`;

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const userListContext = useContext(UserListContext);
  const { dispatch: userListDispatch } = userListContext;
  const [showResetPasswordModal, setShowResetPasswordModal] = React.useState(false);

  const handleSearch = email => {
    searchUsersByEmail(email)
      .then(({ data }) => {
        userListDispatch({ type: "setUserList", userCount: data.length, users: data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const openModal = email => {
    setSelectedUser(email);
    setShowResetPasswordModal(true);
  };

  const onCancel = () => {
    setShowResetPasswordModal(false);
  };

  return (
    <>
      <ResetPasswordModal show={showResetPasswordModal} onClose={onCancel} email={selectedUser} />
      <CardContainer>
        <CardHeader>LDAP Reset Password Tool</CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <form
                onSubmit={event => {
                  event.preventDefault();
                  handleSearch(email);
                }}
                className="form-inline"
              >
                <div className="input-group mt-3">
                  <label htmlFor="inputEmail" className="sr-only">
                    Email
                  </label>
                  <InputStyled
                    id="inputEmail"
                    className={"mr-2"}
                    type="text"
                    placeholder="Enter user's email address"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    disabled={false}
                  />
                  <PrimaryButton>Search</PrimaryButton>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              {userListContext.state.userCount > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Email</th>
                      <th>User</th>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {userListContext.state.users &&
                      userListContext.state.users.map((user, index) => (
                        <tr key={index}>
                          <td width={40} style={{ textAlign: "center" }}>
                            <Icon title="Reset Password" iconName="fa-key" onClick={() => openModal(user.email)} />
                          </td>
                          <td>{user.email}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center">There are no results to display.</div>
              )}
            </div>
          </div>
        </CardBody>
      </CardContainer>
    </>
  );
};

export default ResetPassword;
