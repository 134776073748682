import React, { useContext, useState } from "react";
import UserListContext from "../contexts/UserListContext";
import { searchUsersByEmail } from "../api/UserMgmtApi";
import { CardBody, CardContainer, CardHeader, Icon, PrimaryButton, Table, TableHeader } from "best-common-react";
import { InputStyled } from "./ResetPassword";
import ConfirmLockUpdateModal from "../components/users/ConfirmLockUpdateModal";

const UnlockLockAccount = () => {
  const [email, setEmail] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const userListContext = useContext(UserListContext);
  const { dispatch: userListDispatch } = userListContext;
  const [showConfirmLockUpdateModal, setShowConfirmLockUpdateModal] = React.useState(false);
  const [toLock, setToLock] = useState();

  const handleSearch = email => {
    searchUsersByEmail(email)
      .then(({ data }) => {
        userListDispatch({ type: "setUserList", userCount: data.length, users: data });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const openModal = (email, toLock) => {
    setSelectedUser(email);
    setShowConfirmLockUpdateModal(true);
    setToLock(toLock);
  };

  const onCancel = () => {
    setShowConfirmLockUpdateModal(false);
    handleSearch(email);
  };

  return (
    <>
      <ConfirmLockUpdateModal
        show={showConfirmLockUpdateModal}
        onClose={onCancel}
        email={selectedUser}
        toLock={toLock}
      />
      <CardContainer>
        <CardHeader>LDAP Account Locked Status Tool</CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-12">
              <form
                onSubmit={event => {
                  event.preventDefault();
                  handleSearch(email);
                }}
                className="form-inline"
              >
                <div className="input-group mt-3">
                  <label htmlFor="inputEmail" className="sr-only">
                    Email
                  </label>
                  <InputStyled
                    id="inputEmail"
                    className={"mr-2"}
                    type="text"
                    placeholder="Enter user's email address"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    disabled={false}
                  />
                  <PrimaryButton>Search</PrimaryButton>
                </div>
              </form>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              {userListContext.state.userCount > 0 ? (
                <Table>
                  <TableHeader>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Email</th>
                      <th>User</th>
                      <th>Current Status</th>
                    </tr>
                  </TableHeader>
                  <tbody>
                    {userListContext.state.users &&
                      userListContext.state.users.map((user, index) => (
                        <tr key={index}>
                          <td width={40} style={{ textAlign: "center" }}>
                            <Icon
                              title="Change Account Status"
                              iconName={user.locked ? "fa-lock-open" : "fa-lock"}
                              onClick={() => openModal(user.email, !user.locked)}
                            />
                          </td>

                          <td>{user.email}</td>
                          <td>
                            {user.firstName} {user.lastName}
                          </td>
                          <td>{user.locked ? "Locked" : "Unlocked"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : (
                <div className="text-center">There are no results to display.</div>
              )}
            </div>
          </div>
        </CardBody>
      </CardContainer>
    </>
  );
};

export default UnlockLockAccount;
