import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import React from "react";
import { lockUnlock } from "../../api/UserMgmtApi";
import { useAlert } from "../../hooks/useAlert";

const ConfirmLockUpdateModal = ({ show = false, onClose, email, toLock }) => {
  const showAlert = useAlert();

  const onConfirm = () => {
    lockUnlock(email, toLock)
      .then(({ data }) => {
        if (data === "SUCCESS") {
          onClose();
          showAlert("The user's account status was updated successfully.");
        } else {
          showAlert("There was a problem updating the account status.  Please try again.", "danger");
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Modal show={show} onClose={onClose}>
      <ModalHeaderWithTitleAndClose title={toLock ? "Lock Account" : "Unlock Account"} close={onClose} />
      <ModalBody>
        Are you sure you want to {toLock ? "lock" : "unlock"} the account for {email}?
      </ModalBody>
      <ModalFooter>
        <div>
          <PrimaryButton className="mr-2" onClick={onConfirm}>
            Confirm
          </PrimaryButton>
          <LightButton onClick={onClose}>Cancel</LightButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmLockUpdateModal;
